@import "../../breakpoints.scss";

.footer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	align-items: center;
	padding-top: 1rem !important;

	h1 {
		line-height: 1;
		padding-bottom: 1.5rem;
	}

	@include media(">=phone") {
		padding-top: 0rem !important;
		padding-bottom: 5rem;
	}

	@include media(">=tablet") {
		height: 95vh;
		padding-top: 8rem !important;
	}

	@media (min-height: 890px) {
		gap: 1.5rem;
	}

	@media (max-height: 916px) and (max-width: 415px) {
		padding-top: 2rem;
		line-height: 1.7;
	}

	@include media(">=lgDesktop") {
		height: 90vh;
	}

	h1 {
		padding-top: 2rem !important;
		span {
			color: var(--primary);
		}
	}
	.footer-cards {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
		margin: 0.5rem 0rem;
		gap: 1rem;
		padding: 0.5rem 0;

		@include media(">=phone") {
			margin: 0;
			width: 60%;
		}

		@include media(">=phablet") {
			width: 100%;
			flex-direction: row;
		}

		@include media(">=tablet") {
			width: 80%;
		}

		.footer-card {
			display: flex;
			width: 80vw;
			padding: 0.3rem 0.5rem;
			gap: 1rem;
			align-items: center;
			border-radius: 0.5rem;
			cursor: pointer;
			background: var(--primary);
			transition: all 0.3s ease-in-out;

			@include media(">=phone") {
				width: 100%;
			}

			@include media(">=phablet") {
				margin: 2rem 1rem 1rem;
				padding: 0.5rem;
				width: 42%;
			}

			@include media(">=tablet") {
				margin: 0.2rem;
			}
			@include media(">=lgDesktop") {
				margin: 0.5rem;
				width: 20%;
			}

			svg {
				font-size: 1.2em;
				color: var(--bg-dark2);

				@include media(">=tablet") {
					font-size: 1.5em;
				}
			}

			a {
				color: var(--bg-dark2);
				margin-left: 0.5rem;
				font-size: 0.8em;
				font-weight: bolder;
				:visited {
					color: var(--lightest-slate);
				}

				@include media(">=phablet") {
					font-size: 0.9em;
				}

				@include media(">=tablet") {
					font-size: 1rem;
				}
			}

			@include media(">tablet") {
				&:hover {
					box-shadow: 0 0 2px 2px var(--primary);
					transition: all 0.3 s ease-in;
				}
			}
		}
	}

	.form-container {
		width: 90%;
		position: relative;
		margin: 0rem 1rem;

		@include media(">=tablet") {
			width: 70%;
		}
		@include media(">=lgDesktop") {
			margin: 0.5rem;
			width: 50%;
		}
	}

	.footer-form {
		flex-direction: column;
		object-fit: cover;
		width: 90vw;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 1rem;
		margin-bottom: 0;

		@include media(">=phone") {
			margin-top: 0.7rem;
		}

		div {
			width: 100%;
			margin: 0.5rem 0.5rem;
			border-radius: 10px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			::placeholder {
				color: var(--primary);
				opacity: 0.3; /* Firefox */
				font-size: 0.9rem;

				@include media(">=tablet") {
					font-size: 1rem;
				}
			}

			@media (max-height: 670px) and (max-width: 380px) {
				margin: 0.2rem;
			}

			input,
			textarea {
				font-size: 1rem;
				width: 100%;
				padding: 0.95rem;
				border-radius: 0.5rem;
				background-color: var(--bg-dark2);
				color: var(--primary);

				&:focus {
					outline: none !important;
					border: 1px solid var(--primary);
					box-shadow: 0 0 5px var(--primary);
				}

				@include media(">=tablet") {
					font-size: 1rem;
				}
			}

			textarea {
				resize: none;
				height: 15vh;

				@media (max-height: 670px) and (max-width: 380px) {
					height: 12vh;
				}
			}
		}

		/* Change Autocomplete styles in Chrome*/
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
			-webkit-text-fill-color: var(--primary);
			transition: background-color 5000s ease-in-out 0s;
			box-shadow: 0 0 5px var(--primary);
		}
	}

	//============== FOOTER SOCIALS & ThANKS text ==============//

	.thanks_text {
		height: 33vh;
		display: flex;
		flex-direction: column;
		margin: 0 1rem 3rem 1rem;
		align-items: center;
		justify-content: center;
		width: 100%;

		@include media(">=phablet") {
			margin-bottom: 2.4rem;
		}

		@include media(">=tablet") {
			margin-bottom: 3.8rem;
			height: 30vh;
			margin: 0 1rem 2em 1rem;
		}

		h2 {
			text-align: center;
			color: var(--lightest-slate);
			font-size: 2rem;
			margin: 1rem 0;

			@media (max-height: 670px) and (max-width: 380px) {
				margin: 0;
			}

			@include media(">=phablet") {
				font-size: 2.5rem;
			}

			@include media(">=tablet") {
				font-size: 4rem;
				margin: 2rem;
			}
		}
		h3 {
			text-align: center;
			color: var(--lightest-slate);
			font-size: 1.5rem;
			margin: 1rem;

			@media (max-height: 670px) and (max-width: 380px) {
				margin: 0;
			}

			@include media(">=tablet") {
				font-size: 2rem;
				margin: 2rem;
			}
		}
	}
}

.bottom_part {
	width: 100%;
	height: 25vh;
	position: absolute;
	top: 60%;

	/*================= Send button for the form ===================*/

	.button_div {
		position: absolute;
		top: 2rem;
		width: 100%;

		@include media(">=phone") {
			top: 5rem;
			font-size: 0.9em;
		}

		@include media(">=tablet") {
			top: 7rem;
		}
		@include media(">=lgDesktop") {
			top: 10rem;
		}
	}
	button {
		$button-width: 120px;
		$spinner-width: $button-width/6;
		width: $button-width;
		min-width: $button-width;
		background-color: var(--primary);
		border: 1px solid transparent;
		color: var(--bg-dark);
		font-weight: bold;
		border-radius: 0.5rem;
		text-align: center;
		position: relative;
		cursor: pointer;
		appearance: none;
		-webkit-appearance: none;
		border: 0;
		transition: border-radius linear 0.05s, width linear 0.05s;
		font-size: 0.9rem;
		padding: 0.6rem;
		margin: 0rem 0 0 0;
		width: 8rem;

		@include media(">=tablet") {
			margin: 0.5rem;
			font-size: 1rem;
			width: 9rem;
		}

		&.animate {
			width: $button-width/2;
			height: $button-width/2;
			min-width: 0;
			border-radius: 50%;
			color: transparent;

			@include media(">=phone") {
				width: $button-width/3;
				height: $button-width/3;
			}

			&:after {
				position: absolute;
				content: "";
				width: $spinner-width;
				height: $spinner-width;
				border: 4px solid var(--bg-dark);
				border-radius: 50%;
				border-left-color: transparent;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				animation: spin ease-in 2.5s forwards;
				animation-name: spin;
				-webkit-animation-name: spin;
				transition-timing-function: ease-in-out;
				-webkit-transition-timing-function: ease-in-out;
				animation-duration: 2.5s;
				-webkit-animation-duration: 2.5s;
				animation-fill-mode: forwards;
				-webkit-animation-fill-mode: forwards;
			}

			&.success:before {
				position: absolute;
				content: "";
				width: $spinner-width;
				height: $spinner-width/2;
				border: 4px solid var(--bg-dark);
				border-right: 0;
				border-top: 0;
				left: 50%;
				top: 50%;
				-webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
				transform: translate(-50%, -50%) rotate(0deg) scale(0);
				-webkit-animation: success ease-in 0.15s forwards;
				animation: success ease-in 0.15s forwards;
				animation-delay: 3s;
			}
		}
	}
	/*================= Send button for the form ENDS ===================*/

	.footer-socials {
		position: absolute;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 4rem;
		top: 6rem;

		@include media(">=phone") {
			top: 8rem;
		}

		@include media(">=phablet") {
			font-size: 2rem;
			top: 9rem;

			a {
				font-size: 2rem;
			}
		}

		@include media(">=tablet") {
			top: 12rem;
		}

		@include media(">=laptop") {
			display: none;
		}
	}

	.bottom_info {
		position: absolute;
		top: 10rem;
		margin-top: 1rem;
		position: relative;
		color: var(--slate);
		font-weight: bolder;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include media(">=phone") {
			top: 12rem;
		}
		@include media(">=tablet") {
			margin-top: 4rem;
		}

		small {
			font-size: 0.75rem;

			@include media(">=tablet") {
				font-size: 0.9rem;
			}

			span {
				color: var(--primary);
			}
		}

		@include media(">=phablet") {
			top: 12rem;
		}
	}
}
//============== ENDS FOOTER SOCIALS & TANKS text ==============//

@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg) scale(1);
	}
	90% {
		transform: translate(-50%, -50%) rotate(1080deg) scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
	}

	98% {
		-webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(1);
	}

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(0);
	}
}

@keyframes success {
	from {
		transform: translate(-50%, -50%) rotate(0) scale(0);
	}
	to {
		transform: translate(-50%, -50%) rotate(-45deg) scale(1);
	}
}

@-webkit-keyframes success {
	from {
		-webkit-transform: translate(-50%, -50%) rotate(0) scale(0);
	}
	to {
		-webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(1);
	}
}
