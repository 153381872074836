@import "../../breakpoints.scss";

.portfolio {
	flex: 1;
	width: 100vw;
	flex-direction: column;
	margin-bottom: 2rem;

	h1 {
		line-height: 1;
		padding-bottom: 1.5rem;

		span {
			color: var(--primary);
		}
	}

	.work-filter {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 3rem 0 2rem;

		.filter-item {
			margin: 0.3rem 0.3rem;
			padding: 0.3rem 0.5rem;
			border-radius: 0.5rem;
			background-color: var(--bg-dark2);
			color: var(--primary);
			font-weight: bold;
			cursor: pointer;
			margin: 0.5rem 0.5rem 0 0.5rem;

			@media (max-height: 670px) and (max-width: 380px) {
				padding: 0.3rem 1rem;
			}

			&:hover {
				background-color: var(--primary);
				color: var(--bg-dark);
			}
			@include media(">=lgDesktop") {
				padding: 0.7rem 1.8rem;
				border-radius: 0.75rem;
			}

			@include media(">=2000px") {
				padding: 1rem 2rem;
				border-radius: 0.75rem;
			}
		}

		.item-active {
			background-color: var(--primary);
			color: var(--bg-dark);
		}
	}

	.work-portfolio {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		.work-item {
			width: 350px;
			height: 370px;
			flex-direction: column;
			margin: 1rem 2rem;
			border-radius: 0.5rem;
			background-color: var(--bg-dark2);
			cursor: pointer;

			&:hover {
				box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
			}

			@include media(">=phone") {
				width: 300px;
				border-radius: 0.75rem;
				margin: 1rem 0;
			}

			@include media(">=phablet") {
				width: 320px;
				border-radius: 0.75rem;
				margin: 1rem;
			}

			@include media(">=desktop") {
				width: 435px;
				height: 500px;
				border-radius: 0.75rem;
			}

			@include media("<=300") {
				margin: 1rem;
				width: 100%;
			}
		}
	}

	.work-img {
		width: 100%;
		height: 60%;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			border-radius: 8px 8px 0 0;
			object-fit: cover;
		}

		@include media(">=2000px") {
			height: 350px;
		}
		@include media(">=desktop") {
			height: 300px;
		}
	}

	.work-hover {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 8px 8px 0 0;
		opacity: 0;

		div {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: rgba(33, 33, 33, 0.878);
			color: var(--slate);
			margin: 1rem;
			font-weight: bold;
			cursor: pointer;

			svg {
				width: 50%;
				height: 50%;
				color: var(--primary);
			}
		}
	}

	.work-content {
		width: 100%;
		height: 40%;
		position: relative;
		flex-direction: column;
		justify-content: space-around;

		.work-tag {
			font-weight: bolder;
			border-radius: 5px;
			position: absolute;
			padding: 0.2rem 0.5rem;
			background-color: var(--bg-dark2);
			top: -20px;
		}

		h1 {
			margin-top: 1rem;
			font-weight: normal;
			text-align: left;
			color: var(--lightest-slate);
			padding-bottom: 0.5rem;

			@include media(">=phone") {
				margin-top: 1rem;
				font-size: 1rem;
			}
			@include media(">=tablet") {
				margin-top: 1.2rem;
				font-size: 1.2rem;
			}

			@media screen and (min-width: 2000px) {
				margin-top: 3rem;
			}
		}
		small {
			padding: 0 0.5rem;
			color: var(--lightest-slate);

			@include media(">=phone") {
				font-size: 0.8rem;
			}
			@include media(">=tablet") {
				font-size: 0.9rem;
			}
		}

		.stack {
			flex-wrap: wrap;
			font-size: 10px;
			margin-top: 0.5rem;
			padding: 0.5rem;

			span {
				font-weight: bolder;
				color: var(--light-slate);
				background-color: var(--bg-dark);
				border-radius: 5px;
				margin: 10px 3px 0;
				padding: 2px 4px;

				@include media(">=phone") {
					padding: 0.2rem 0.4rem;
				}
				@include media(">=tablet") {
					margin: 20px 3px 0;
				}
				@include media(">=desktop") {
					margin: 20px 3px 0;
					padding: 0.3rem 0.8rem;
					font-size: 0.8rem;
				}
			}
		}
	}
}
