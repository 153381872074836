@import "../../breakpoints.scss";

.about {
	h1 {
		span {
			color: var(--primary);
		}
	}
	.info_sections {
		margin: 1rem 0 3rem 0;
		display: flex;
		flex-direction: column;

		@media (max-height: 670px) and (max-width: 380px) {
			line-height: 1.4;
			margin: 0 0 2rem 0;
			font-size: 1rem;
		}
		@include media(">=phone") {
			padding-top: 1rem;
			margin: 0 0 1.5rem 0;
			gap: 1rem;
		}

		@include media(">=tablet") {
			margin: 2rem 0 2rem 0;
			padding: 0;
		}

		@include media(">=desktop") {
			height: 45rem;
			width: 100%;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		@include media(">=lgDesktop") {
			padding-left: 2rem;
		}

		.general {
			display: flex;
			position: relative;
		}

		.info_title {
			h1 {
				color: var(--primary);
				font-size: 2.2rem;
				position: absolute;
				transform: rotate(-90deg);
				left: -70px;
				top: 150px;
				line-height: 1;
				height: auto;
				width: auto;

				@include media(">=phone") {
					left: -70px;
				}
				@include media(">=phablet") {
					font-size: 1.8rem;
					left: -45px;
					top: 130px;
				}
				@include media(">=tablet") {
					left: -5px;
					font-size: 2.2rem;
					top: 150px;
					font-size: 2.2rem;
				}
				@include media(">=desktop") {
					font-size: 3rem;
					left: -100px;
					top: 160px;
					z-index: 1;
					line-height: 1.2;
					height: 100px;
					width: 300px;
				}
				@include media(">=lgDesktop") {
					font-size: 3.5rem;
					top: 16rem;
					left: -6.5rem;
				}
			}
			.interest {
				top: 80px;
				@include media(">=500px", "<750px") {
					top: 80px;
				}
				@include media(">=phone") {
					top: 70px;
					left: -65px;
					font-size: 2rem;
				}
				@include media(">=phablet") {
					font-size: 1.8rem;
					left: -45px;
				}
				@include media(">=tablet") {
					top: 80px;
					font-size: 2.2rem;
					left: -1px;
				}
				@include media(">=desktop") {
					font-size: 3rem;
					left: -100px;
					top: 160px;
					z-index: 1;
					line-height: 1.2;
					height: 100px;
					width: 300px;
				}
				@include media(">=lgDesktop") {
					font-size: 3.5rem;
					top: 16rem;
					left: -6.5rem;
				}
			}
		}

		.section_info {
			margin: 0.5rem 0;
			padding: 0.5rem;
			background: #112240bb;
			border-radius: 10px;
			box-shadow: black 30px 30px 30px -45px;
			backdrop-filter: blur(2px);

			@include media(">=phablet") {
				margin: 0.5rem 1rem;
			}

			@include media(">=tablet") {
				margin: 1.5rem 4.5rem;
				transform-origin: 0 0;
				transition: transform 0.5s, visibility 0.5s ease-in-out;
			}

			@include media(">=desktop") {
				position: absolute;
				margin: auto;
				padding: 0.4rem;
				overflow: hidden;
				left: 30px;
				top: 40px;
				z-index: 2;
				width: 320px;
				height: 90%;
				transform-origin: 0 0;
				transition: transform 0.5s, visibility 0.5s ease-in-out;
			}
			@include media(">=lgDesktop") {
				width: 350px;
			}

			.text_title {
				font-size: 0.7rem;
				padding: 0 0.5rem;
				display: flex;
				justify-content: space-between;
				justify-items: center;
				align-items: center;
				color: var(--lightest-slate);

				@include media(">=tablet") {
					margin: 0rem 0.5rem;
					font-size: 0.7rem;
				}

				@include media(">=desktop") {
					padding: 0.5rem;
				}

				span {
					padding-top: 0.5rem;
					font-size: 1.6rem;
					color: var(--primary);

					@include media(">=tablet") {
						padding-top: 0;
						margin: 0rem 0.5rem;
						font-size: 1.7rem;
					}
				}
			}
			@include media(">=tablet") {
				&:hover {
					transform: scale(1.05);
					box-shadow: black 35px 35px 35px -43px;
				}
			}

			p {
				margin-top: -2rem;
				padding: 0.5rem 0.5rem;
				color: var(--lightest-slate);
				font-size: 0.9rem;

				@media (min-height: 916px) and (min-width: 415px) {
					font-size: 1rem;
				}

				@include media(">=tablet") {
					font-size: 1rem;
					margin: 0rem 0.5rem;
					transform: translateY(-20px);
				}

				@include media(">=desktop") {
					margin: 0rem 0.2rem;
					font-size: 1.1rem;
				}
				@include media(">=desktop") {
					line-height: 1.7;
				}
			}
		}

		&:nth-child(2) {
			p {
				span {
					font-size: 2rem;
					margin-left: 2rem;
					color: var(--primary);
				}
			}
		}

		.lifestyle {
			display: flex;
			position: relative;
		}

		.interest {
			display: flex;
			position: relative;
		}
	}
}
