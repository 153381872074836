@import "../../breakpoints.scss";

.scrollDiv {
	position: fixed;
	z-index: 999;
	display: none;

	@include media(">=tablet") {
		display: block;
		bottom: 2rem;
		right: 2rem;
	}
	@include media(">=laptop") {
		bottom: 5rem;
		right: 5rem;
	}

	.scrollBtn {
		opacity: 0.9;
		display: flex;
		padding: 3px;
		cursor: pointer;
		border: none;
		background: transparent;
		font-size: 2.5rem;
		animation: fadeIn 0.5s;
		transition: opacity 0.4s;

		svg {
			color: var(--lightest-slate);
			opacity: 0.8;

			&:hover {
				transition: all 0.4s ease-out;
				color: var(--primary);
				opacity: 1;
				background-color: var(--bg-dark2);
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.9;
	}
}
