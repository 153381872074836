@import "../../breakpoints.scss";

.skills {
	h1 {
		span {
			color: var(--primary);
		}
	}
	.frontend {
		flex-flow: row wrap;
	}

	.special {
		line-height: 1.2;
	}

	.sections {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 2rem;
		margin-top: 2rem;

		@include media(">=tablet") {
			margin-top: 4rem;
		}

		@include media(">=laptop") {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-top: 1rem;
			padding: 4rem 0rem 0;
			column-gap: 2rem;
		}

		.sectionImg {
			aspect-ratio: 1/1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 80%;
			margin: 4rem 0 2rem 0;

			@include media(">=phablet") {
				width: 75%;
			}
			@include media(">=tablet") {
				margin-top: 2rem;
				width: 75%;
			}
			@include media(">=laptop") {
				width: 80%;
			}
			@include media(">=desktop") {
				width: 90%;
			}
			@include media(">=lgDesktop") {
				width: 80%;
			}

			img {
				padding: 0.5rem;
				z-index: 1;
				width: 100%;
				height: 75%;
				object-fit: cover;
			}

			.bgCircle {
				aspect-ratio: 1/1;
				width: 100%;
				background: linear-gradient(320deg, #0a192f, #64ffdb62);
				border-radius: 50%;
				position: absolute;
				z-index: 0;
			}
		}
		.logos {
			@include media(">=tablet") {
				padding: 1rem;
				margin: 7rem;
			}

			@include media(">=laptop") {
				padding: 1rem;
				margin: 0rem;
			}
			@include media(">=desktop") {
				padding: 2rem;
			}
			@include media(">=lgDesktop") {
				padding: 4rem;
			}

			.skills-logos {
				display: grid;
				grid-template-columns: repeat(3, 1fr);

				@include media(">=phone") {
					gap: 1.5rem;
				}
				@include media(">=phablet") {
					gap: 2rem;
				}

				div {
					padding: 0.2rem 0.5rem;
					flex-direction: column;

					@include media(">=phone") {
						margin: 0rem;
						padding: 0rem;
					}

					@include media(">=phablet") {
						padding: 0.5rem;
						margin: 0.5rem;
					}

					@include media(">=tablet") {
						padding: 0.2rem;
						margin: 0rem;
					}
					@include media(">=laptop") {
						padding: 0rem;
						margin: 0rem;
					}
					div {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						background: var(--bg-dark);
						box-shadow: 0 0 10px var(--primary);
						margin: 1rem;
						padding: 1.2rem;

						@include media(">=phone") {
							margin: 0.1rem;
							padding: 1rem;
						}
						@include media(">=phablet") {
							margin: 0rem;
						}

						@include media(">=tablet") {
							width: 80%;
							height: 80%;
							padding: 1.5rem;
						}

						@include media(">=laptop") {
							padding: 1.5rem;
							margin: 0rem;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					span {
						transform: translateY(-5px);
						font-size: 0.7rem;
						margin-top: 1rem;
						color: var(--lightest-slate);
						font-weight: bolder;

						@include media(">=phablet") {
							font-size: 1rem;
						}
						@include media(">=tablet") {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}

	.backend {
		.sections {
			flex-direction: column-reverse;

			.sectionImg2 {
				@include media(">=laptop") {
					margin-left: 6rem;
				}
				@include media(">=desktop") {
					margin-left: 3rem;
				}
				@include media(">=lgDesktop") {
					margin-left: 6rem;
				}
			}
		}
	}
}
