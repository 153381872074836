@import "../../breakpoints.scss";

nav {
	align-items: center;
	background: #0a192f86;
	position: fixed;
	z-index: 1000;
	align-items: center;
	justify-content: space-between;
	display: flex;
	box-shadow: rgb(0, 0, 0) 0px 40px 40px 35px;
	height: 3rem;
	bottom: 0;
	backdrop-filter: blur(8px) saturate(100%);
	-webkit-backdrop-filter: blur(8px) saturate(125%);
	width: 100vw;

	@include media(">=phone") {
		padding: 0.5rem 0.2rem;
		margin: 0;
	}

	@include media(">=tablet") {
		padding: 0.5rem 4rem;
		height: 5rem;
		top: 0;
		box-shadow: black 0px 35px 30px -35px;
	}

	@include media(">=laptop") {
		height: 5rem;
		padding: 0.5rem 3rem;
	}
	@include media(">=desktop") {
		padding: 0.5rem 2rem;
	}
	@include media(">=lgDesktop") {
		padding: 0.5rem 10rem;
	}

	.n-right {
		display: flex;
		gap: 2rem;
	}
	/*======== LOGO =========*/

	.navbar-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		position: relative;
		z-index: 2;
		@include media("<=tablet") {
			display: none;
		}

		h1 {
			color: var(--lightest-slate);
			font-size: 2.25rem;
			transform: translateX(2px);
		}

		.logoCircle {
			width: 3rem;
			height: 3rem;
			background: linear-gradient(-45deg, transparent, #64ffdb62);
			border-radius: 50%;
			position: absolute;
			z-index: -1;
			//transform: translateY(-10px);
		}
	}

	//======== MOBILE MENU =========*
	.mobile-icons {
		margin: 0 0.1rem;

		a {
			margin: 0 0.9rem;
			font-size: 1rem;
			color: var(--lightest-slate);

			@include media(">=phone") {
				margin: 0 1.4rem;
				font-size: 1.5rem;
			}

			@include media(">=phablet") {
				margin: 0 2.5rem;
				font-size: 1.5rem;
			}
		}

		@include media(">=phablet") {
			margin-left: 2.5rem;
			font-size: 1.5rem;
		}

		@include media(">=tablet") {
			display: none;
		}
	}
	//======== MOBILE MENU =========*

	/*======== LINKS =========*/

	.navbar-links {
		flex-grow: 2;
		display: flex;

		@include media("<tablet") {
			display: none;
		}

		li {
			margin: 0 1rem;
			cursor: pointer;
			display: flex;
			&::before {
				transition: 300ms;
				height: 5px;
				content: "";
				position: absolute;
			}

			a {
				color: var(--lightest-slate);
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 500;
				display: inline-block;
				padding-top: 1.2rem;
				height: 70px;

				position: relative;

				@include media(">=phablet") {
					padding-top: 1.4rem;
					font-size: 1rem;
				}

				&:hover {
					color: var(--primary);
				}
			}
		}

		@include media(">=tablet") {
			margin-left: 0rem;

			li {
				margin: 0 1rem;
			}
		}
	}
	/* .active {
		//border-bottom: 2px solid var(--primary);
	} */

	.nav-link::before {
		transition: 300ms;
		height: 0.1px;
		content: "";
		position: absolute;
		background-color: var(--primary);
	}

	.nav-link-ltr::before {
		width: 0%;
		bottom: 0;
	}

	.nav-link-ltr:hover::before {
		width: 100%;
	}

	/*====== Socials =========*/
	.navbar-socials {
		flex-grow: 1;

		a {
			&:hover,
			&:focus {
				transform: translateY(-3px);
				color: var(--primary);
				transition: all 0.3s ease-in-out;
			}
		}

		@include media("<=laptop") {
			display: none;
		}
	}
}
