@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
	scroll-behavior: smooth;
}

:root {
	--font-base: "Poppins", sans-serif;
	--bg-dark: #0a192f;
	--bg-dark2: #112240;
	--bg-dark3: #233554;

	--primary: #64ffda;

	--slate: #8892b0;
	--light-slate: #a8b2d1;
	--lightest-slate: #ccd6f6;
}

body,
button,
input,
select,
textarea {
	font-family: var(--font-base);
}
