@import "./breakpoints.scss";

::-webkit-scrollbar {
	display: none;
}

.app {
	background-color: var(--bg-dark);
	background-image: url(./assets/bgImg.svg);
	color: var(--lightest-slate);
	line-height: 1.7;
}

/*=============== GENERAL CLASSES ====================*/

.container {
	max-width: 92rem;
	margin: 0 auto;
	min-height: 100vh;
	padding: 0rem;

	@include media(">=phone") {
		padding: 0rem 2rem;
	}
	@include media(">=phablet") {
		padding: 0rem 3rem;
	}
	@include media(">=tablet") {
		padding: 0rem 3rem;
	}
	@include media(">=laptop") {
		padding: 0rem 2rem;
	}
	@include media(">=desktop") {
		padding: 0rem 4rem;
	}
	@include media(">=lgDesktop") {
		padding: 0 2rem;
	}
}

.btn {
	width: max-content;
	display: inline-block;
	color: var(--primary);
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--primary);
	transition: all 0.6s ease-in-out;
	text-align: center;
	font-size: 0.8rem;
	padding-top: 1.5rem;
	padding: 0.5rem 0.5rem;

	@include media(">=phone") {
		text-align: center;
		font-size: 0.85rem;
	}
	@include media(">=tablet") {
		//text-align: center;
		font-size: 1rem;
	}
	@include media(">=laptop") {
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 2.5rem;
		padding: 0.5rem 1rem;
	}
}

.btn:hover {
	background: var(--primary);
	color: var(--bg-dark);
	border-color: transparent;
}

.btn-primary {
	width: max-content;
	background: var(--primary);
	color: var(--bg-dark);
	width: max-content;
	display: inline-block;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--primary);
	transition: all 0.6s ease-in-out;
	text-align: center;
	font-size: 0.75rem;
	padding: 0.5rem 0.5rem;

	@include media(">=phone") {
		margin-bottom: 0.2rem;
		text-align: center;
		font-size: 0.85rem;
	}
	@include media(">=tablet") {
		//text-align: center;
		font-size: 1rem;
	}
	@include media(">=laptop") {
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 2.5rem;
		padding: 0.5rem 1rem;
	}
}
.btn-primary:hover {
	background: none;
	color: var(--primary);
	border-radius: 0.4rem;
	border: 1px solid var(--primary);
	transition: var(--transition);
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.section_title {
	font-size: 2rem;
	text-align: center;
	color: var(--lightest-slate);
	line-height: 1;

	@include media(">=phone") {
		font-size: 3rem;
		padding-top: 2rem;
	}

	@include media(">=tablet") {
		font-size: 4rem;
		padding-top: 6rem;
	}
	@include media(">=lgDesktop") {
		padding-top: 8rem;
	}
}

.p-text {
	font-size: 1rem;
	text-align: left;
	color: var(--lightest-slate);
	line-height: 1.5;
	font-weight: 300;
}

.socials {
	display: flex;
	align-items: center;
	height: 100%;

	a {
		margin: 0 0.7rem 0;
		display: block;
		font-size: 1.8rem;
		color: var(--lightest-slate);
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}
}
