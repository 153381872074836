@import "../../breakpoints.scss";

.header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;

	@include media(">=phone") {
		justify-content: center;
		gap: 2rem;
	}

	@include media(">=phablet") {
		padding-top: 4rem;
		height: 90vh;
		gap: 2rem;
		justify-content: flex-start;
	}

	@include media(">=tablet") {
		padding-top: 8rem;
	}
	@include media(">=laptop") {
		flex-direction: row-reverse;
		justify-content: center;
		padding-top: 0rem;
	}

	@include media(">=desktop") {
		justify-content: center;
		gap: 4rem;
	}
}

.container_img {
	margin: 0 auto;
	padding-top: 1rem;
	width: 75%;

	@include media(">=phablet") {
		padding: 0 2rem;
		width: 55%;
	}
	@include media(">=tablet") {
		padding: 0 2rem;
		width: 60%;
	}
	@include media(">=laptop") {
		padding: 0rem;
		width: 100%;
	}
}

.imgME {
	aspect-ratio: 1/1;
	width: 100%;
	border-radius: 50%;
}

.meCircle {
	aspect-ratio: 1/1;
	background: linear-gradient(#64ffdb62, transparent);
	width: 100%;
	border-radius: 50%;
	padding: 0 0 0 0.5rem;
	box-shadow: #64ffdb62 -30px 60px 120px -30px,
		rgba(0, 0, 0, 0.438) 0px 30px 60px -30px;

	@include media(">=phone") {
		width: 100%;
	}

	@include media(">=tablet") {
		width: 100%;
	}

	@include media(">=desktop") {
	}
}

.info-left {
	text-align: center;
	line-height: 1.5;

	@include media(">=phone") {
		line-height: 1.7;
	}

	@include media(">=phablet") {
		line-height: 2;
	}
	@include media(">=tablet") {
		line-height: 1.6;
	}

	@include media(">=laptop") {
		text-align: left;
		line-height: 1.9;
		padding-right: 2rem;
	}

	.hello {
		padding-top: 1rem;
		color: var(--lightest-slate);

		@include media(">=phablet") {
			font-size: 1.2rem;
			padding-top: 0;
		}
		@include media(">=tablet") {
			font-size: 1.5rem;
		}
		@include media(">=laptop") {
			font-size: 2rem;
		}
		span {
			font-size: 1.8rem;

			@include media(">=laptop") {
				font-size: 2.5rem;
			}
		}
	}

	h1 {
		font-size: 2rem;
		color: var(--lightest-slate);

		@include media(">=phone") {
			font-size: 2.5rem;
		}
		@include media(">=tablet") {
			font-size: 4rem;
		}
		@include media(">=laptop") {
			font-size: 3.5rem;
			text-align: left;
		}

		@include media(">=desktop") {
			font-size: 5rem;
		}
		@include media(">=lgDesktop") {
			font-size: 6rem;
		}
	}

	h2 {
		color: var(--primary);
		font-size: 1.2rem;

		@include media(">=laptop") {
			font-size: 1.4rem;
		}
	}

	p {
		padding-top: 2rem;
		font-weight: 500;

		@media (max-height: 670px) {
			padding-top: 1rem;
			font-size: 0.8rem;
		}

		@include media(">=phone") {
			padding-top: 0.5rem;
			font-size: 0.8rem;
		}
		@include media(">=phablet") {
			padding-top: 1rem;
		}

		@include media(">=tablet") {
			font-size: 1rem;
		}
	}
}

/*========CTA=======*/

.cta {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1rem;

	@include media(">=phone") {
		padding-top: 0rem;
		padding-bottom: 5rem;
	}
	@include media(">=phablet") {
		padding-bottom: 1rem;
		margin-top: 3rem;
		line-height: 2;
	}
	@include media(">=tablet") {
		padding-top: 0rem;
	}
	@include media(">=laptop") {
		justify-content: flex-start;
		line-height: 1.5;
	}

	a {
		margin: 0 0.3rem;

		@include media(">=phone") {
			padding: 0.3rem;
			margin: 2rem 1rem 0rem;
		}
		@include media(">=phablet") {
			padding: 0.5rem;
			margin: 0rem 1rem 2rem;
		}

		@include media(">=tablet") {
			margin: 0 2rem;
			font-size: 0.9rem;
		}

		@include media(">=laptop") {
			margin-left: 0;
		}
	}
}

.icon-scroll,
.icon-scroll:before {
	position: absolute;
	left: 46%;
	right: 50%;

	@include media(">=750px") {
		top: 85%;
		left: 46.5%;
		right: 50%;
	}

	@include media(">=tablet") {
		top: 93%;
		left: 47%;
		right: 50%;
	}
	@include media(">=laptop") {
		left: 50%;
		right: 50%;
		top: 90%;
	}
}
.icon-scroll {
	width: 2rem;
	height: 3.2rem;
	bottom: 4rem;
	border: 2px solid var(--primary);
	border-radius: 2rem;

	@include media("<=phablet") {
		display: none;
	}
}

.icon-scroll:before {
	content: "";
	width: 8px;
	height: 8px;
	background: var(--primary);
	margin-left: -2px;
	top: 8px;
	border-radius: 4px;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: scroll;
	@include media(">=tablet") {
		margin-left: -4px;
	}
}
@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(26px);
	}
}
